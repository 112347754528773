h1 {
  text-align: center;
}

.user-info {
  align-items: center;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  list-style: none;
  padding: 0;
}

.user-info button {
  background: var(--dark-gray);
  border: 0;
  border-radius: 0.5rem;
  color: var(--white);
  display: block;
  font-family: var(--font-family);
  font-size: 1.5rem;
  font-weight: 900;
  padding: 1rem;
  text-align: center;
  text-decoration: none;
}

.corgi-content {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
}

.content h2 {
  font-size: 1.25rem;
  text-align: center;
}

.content-display {
  margin: 0;
}

.credit {
  display: block;
  font-size: 0.75rem;
}

.content img {
  width: 100%;
}

button {
  /* Not sure why this is needed */
  cursor: pointer;
}

body {
  background-color: black;
  height: 100%; }

.card-wrapper {
  display: flex;
  min-height: 80%;
  max-height: 100%;
  width: 100%;
  padding-top: 20px;
  box-sizing: border-box;
  background-repeat: repeat-x repeat-y; }

.card-lane-recurring, .card-lane-icebox, .card-lane-dnd-storytelling, .card-lane-roadmaps {
  background-color: #330000 !important; }

.card-lane-backlog, .card-lane-dnd-future-devs {
  background-color: #003300 !important; }

.card-lane-today, .card-lane-dnd-game {
  background-color: #000033 !important; }

.card-lane-upcoming, .card-lane-dnd-characters, .card-lane-async {
  background-color: #260026 !important; }

.card-lane-finished, .card-lane-dnd-finished {
  background-color: #000033 !important; }

.card-card-stack-hump {
  background-color: #111;
  border: solid #42454a 1px;
  border-top: none;
  height: 10px; }

.card-lane {
  flex-direction: row;
  align-items: stretch;
  background-color: #202225;
  margin: 2px;
  flex: 1;
  flex-basis: 100%;
  padding: 10px; }
  .card-lane .card-lane-cards {
    max-height: calc(100% - 45px);
    overflow: auto;
    scrollbar-width: none; }
    .card-lane .card-lane-cards::-webkit-scrollbar {
      display: none; }

.card-lane-showDescriptions {
  float: right;
  color: #ddd;
  display: block !important;
  cursor: pointer; }

.card-lane-title {
  padding-bottom: 15px;
  position: relative;
  width: 100%; }

.card-lane-title-inner {
  display: inline-block;
  color: #DBDEE3;
  padding: 7px;
  padding-right: 15px;
  height: 40px;
  font-family: Orbitron;
  font-size: 25px; }

.card-card, .card-cardCollapsed {
  margin: 40px 0; }
  .card-card.card-selected, .card-cardCollapsed.card-selected {
    border-color: #767c85; }

.card-placeholder {
  height: 130px; }

.card-card {
  border: solid #42454a 1px;
  padding: 20px;
  padding-bottom: 50px;
  margin: 15px auto;
  cursor: pointer;
  flex-basis: 100%;
  font-family: bebas_neuebold;
  background-color: rgba(17, 17, 17, 0.3); }

.card-cardCollapsed {
  border-bottom: solid #42454a 1px;
  margin-top: 10px;
  cursor: pointer; }

.card-card-stacked {
  margin-bottom: 0px; }

.card-title {
  font-size: 19px;
  font-weight: 700;
  color: #DBDEE3;
  font-family: "Open Sans";
}

.card-title input {
  background-color: rgba(50, 50, 50, 0.4);
  color: white;
  font-weight: 700;
  font-size: 19px; }

.card-description {
  font-size: 17px;
  margin-top: 10px;
  color: #ddd; }

.card-add {
  font-size: 13px;
  color: #666;
  margin-top: 15px;
}

.card-add a {
  color: white;
}

.card-copy, .card-delete, .card-collapse, .card-hideSection {
  float: right;
  color: #42454a;
  font-size: 13px;
  margin: 0 3px; }

.card-copy {
  margin-left: 5px; }

.card-delete {
  margin-right: 0; }
