h1 {
  text-align: center;
}

.user-info {
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 1rem;
  padding: 0;
  list-style: none;
  display: grid;
}

.user-info button {
  background: var(--dark-gray);
  color: var(--white);
  font-family: var(--font-family);
  text-align: center;
  border: 0;
  border-radius: .5rem;
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: 900;
  text-decoration: none;
  display: block;
}

.corgi-content {
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  display: grid;
}

.content h2 {
  text-align: center;
  font-size: 1.25rem;
}

.content-display {
  margin: 0;
}

.credit {
  font-size: .75rem;
  display: block;
}

.content img {
  width: 100%;
}

button {
  cursor: pointer;
}

body {
  height: 100%;
  background-color: #000;
}

.card-wrapper {
  min-height: 80%;
  max-height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-repeat: repeat-x repeat-y;
  padding-top: 20px;
  display: flex;
}

.card-lane-recurring, .card-lane-icebox, .card-lane-dnd-storytelling, .card-lane-roadmaps {
  background-color: #300 !important;
}

.card-lane-backlog, .card-lane-dnd-future-devs {
  background-color: #030 !important;
}

.card-lane-today, .card-lane-dnd-game {
  background-color: #003 !important;
}

.card-lane-upcoming, .card-lane-dnd-characters, .card-lane-async {
  background-color: #260026 !important;
}

.card-lane-finished, .card-lane-dnd-finished {
  background-color: #003 !important;
}

.card-card-stack-hump {
  height: 10px;
  background-color: #111;
  border: 1px solid #42454a;
  border-top: none;
}

.card-lane {
  background-color: #202225;
  flex-direction: row;
  flex: 100%;
  align-items: stretch;
  margin: 2px;
  padding: 10px;
}

.card-lane .card-lane-cards {
  max-height: calc(100% - 45px);
  scrollbar-width: none;
  overflow: auto;
}

.card-lane .card-lane-cards::-webkit-scrollbar {
  display: none;
}

.card-lane-showDescriptions {
  float: right;
  color: #ddd;
  cursor: pointer;
  display: block !important;
}

.card-lane-title {
  width: 100%;
  padding-bottom: 15px;
  position: relative;
}

.card-lane-title-inner {
  color: #dbdee3;
  height: 40px;
  padding: 7px 15px 7px 7px;
  font-family: Orbitron;
  font-size: 25px;
  display: inline-block;
}

.card-card, .card-cardCollapsed {
  margin: 40px 0;
}

.card-card.card-selected, .card-cardCollapsed.card-selected {
  border-color: #767c85;
}

.card-placeholder {
  height: 130px;
}

.card-card {
  cursor: pointer;
  background-color: #1111114d;
  border: 1px solid #42454a;
  flex-basis: 100%;
  margin: 15px auto;
  padding: 20px 20px 50px;
  font-family: bebas_neuebold;
}

.card-cardCollapsed {
  cursor: pointer;
  border-bottom: 1px solid #42454a;
  margin-top: 10px;
}

.card-card-stacked {
  margin-bottom: 0;
}

.card-title {
  color: #dbdee3;
  font-family: Open Sans;
  font-size: 19px;
  font-weight: 700;
}

.card-title input {
  color: #fff;
  background-color: #32323266;
  font-size: 19px;
  font-weight: 700;
}

.card-description {
  color: #ddd;
  margin-top: 10px;
  font-size: 17px;
}

.card-add {
  color: #666;
  margin-top: 15px;
  font-size: 13px;
}

.card-add a {
  color: #fff;
}

.card-copy, .card-delete, .card-collapse, .card-hideSection {
  float: right;
  color: #42454a;
  margin: 0 3px;
  font-size: 13px;
}

.card-copy {
  margin-left: 5px;
}

.card-delete {
  margin-right: 0;
}

:root {
  --pink: #d459ab;
  --pink-text: #dd78bc;
  --yellow: #ffdf37;
  --blue: #a6fffa;
  --light-gray: #e0e0e0;
  --dark-gray: #1a2a3b;
  --gray: #3f4f61;
  --black: #011627;
  --black-transparent: #01162700;
  --white: white;
  --lwj-gradient: linear-gradient( 90deg, var(--pink) 0%, var(--yellow) 25%, var(--blue) 50%, var(--yellow) 75%, var(--pink) 100% );
  --text: var(--gray);
  --text-muted: #687889;
  --font-family: mallory, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

* {
  box-sizing: border-box;
}

html, body {
  background: var(--white);
  color: var(--text);
  margin: 0;
  font-family: mallory, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: 400;
}

body {
  min-height: 100vh;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--black);
  font-weight: 900;
}

main {
  max-width: 640px;
  min-height: calc(100vh - 242px);
  width: 90vw;
  margin: 4rem auto;
}

@keyframes gradient-roll {
  0% {
    background-position: -100vw 0;
  }

  100% {
    background-position: 0 0;
  }
}

/*# sourceMappingURL=index.95c0fa4a.css.map */
