:root {
  --pink: #d459ab;
  --pink-text: #dd78bc; /* lightened up to meet a11y guidelines */
  --yellow: #ffdf37;
  --blue: #a6fffa;
  --light-gray: #e0e0e0;
  --dark-gray: #1a2a3b;
  --gray: #3f4f61;
  --black: #011627;
  --black-transparent: #01162700;
  --white: white;
  --lwj-gradient: linear-gradient(
    90deg,
    var(--pink) 0%,
    var(--yellow) 25%,
    var(--blue) 50%,
    var(--yellow) 75%,
    var(--pink) 100%
  );
  --text: var(--gray);
  --text-muted: #687889;
  --font-family: mallory, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}

* {
  box-sizing: border-box;
}

html,
body {
  background: var(--white);
  color: var(--text);
  font-family: mallory, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-weight: 400;
  margin: 0;
}

body {
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--black);
  font-weight: 900;
}

main {
  margin: 4rem auto;
  max-width: 640px;
  min-height: calc(100vh - 242px);
  width: 90vw;
}

@keyframes gradient-roll {
  0% {
    background-position: -100vw 0;
  }

  100% {
    background-position: 0 0;
  }
}
